.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fffffe;

  h1 {
    margin-top: 3rem;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.card {
  margin: 1rem;
  flex-basis: 45%;
  padding: 3rem;
  text-align: left;
  color: inherit;
  background-color: #A2BDCC;
  text-decoration: none;
  border: 1px solid #A2BDCC;
  border-radius:5px;

  a {
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid;
    border-radius: 5px;
    padding: .5rem;
  }

  h1 {
    margin: 0;
    font-weight: 400;
    font-size: 1.5rem;
  }

  h2 {
    margin: 2rem 10rem 2rem 10rem;
    font-size: 1.5rem;
    font-weight: 200;
  }

  h3 {
    margin: 2rem 0 .5rem 0;
    font-weight: 500;
    font-size: 2rem;
  }

  p {
    margin: 0 0 2rem 0;
    font-size: 1.5rem;
    font-weight: 300;
  }
}


.skillsGrid{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.skills p {
    text-align: center;
    margin-bottom: .5rem;
}

.portfolioCard {
  margin: 1rem;
  flex-basis: 45%;
  padding: 3rem;
  text-align: left;
  color: inherit;
  background-color: #A2BDCC;
  text-decoration: none;
  border: 1px solid #A2BDCC;
  border-radius:5px;

  a {
    display:inline-block;
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid;
    border-radius: 5px;
    padding: .5rem;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
  }

  h1 {
    margin: 0;
    font-weight: 400;
    font-size: 1.5rem;
  }

  h2 {
    margin: 2rem 10rem 2rem 10rem;
    font-size: 1.5rem;
    font-weight: 200;
  }

  h3 {
    margin: 2rem 0 .5rem 0;
    font-weight: 500;
    font-size: 2rem;
  }

  p {
    margin: 1rem 0 0rem 0;
    font-size: 1em;
    font-weight: 300;
    text-align: center;
  }

  a:hover {
    color: white;
    transition-duration: .5s;
  }
}

.portfolioFrame{
  width: 100%;
  height: 800px;
  border-radius:5px;
  margin-top: .5rem;
  background-color: white;
}

.popup {
  font-weight: 500;
  font-size: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  height: 2rem;
  border: none;
  background-color: #fff;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transition: .2s;
}

.contactCard{
  margin: 1rem;
  flex-basis: 45%;
  padding: 3rem;
  text-align: center;
  color: inherit;
  background-color: #A2BDCC;
  text-decoration: none;
  border: 1px solid #A2BDCC;
  border-radius:5px;

  h1 {
    margin: 0;
    font-weight: 400;
    font-size: 1.5rem;
    text-align: left;
  }

  h2 {
    margin: 1rem 0 0 0;
    font-weight: 700;
    font-size: 1.5rem;
  }

  h3 {
    margin: 0;
    font-weight: 200;
    font-size: 1.25rem;
  }
}

.footer{
  margin-top: 2rem;

  p {
    font-size: .75rem;
    margin: 2rem 0 1rem 0;
  }
}

.socialLinks {
  margin: 0 1rem 0 1rem
}

.flexCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textLarge {
  font-size: 40px !important;
}

.phone {
  border: none !important;
  font-size: 40px !important;
}

@media (min-width: 600px) {
  .portfolioCard p {
    display: none;
  }
}

@media (max-width: 1000px) and (min-width: 600px) {
  .card h2 {
    margin: 2rem 4rem 2rem 4rem;
    font-size: 1.5rem;
    font-weight: 200;
  }

}

@media (max-width: 600px) {

  .phone {
    font-size: 28px !important;
  }

  .grid {
    width: 100%;
    flex-direction: column;
    margin-top: 1rem;
  }

  .card {
    padding: 1.25rem;

    h1 {
      text-align: center;
    }

    h2 {
      margin: 1rem 0 0 0;
      font-size: 1.25rem;
      font-weight: 200;
    }

    h3 {
      margin: 1.25rem 0 .25rem 0;
      font-size: 1.25rem;
      font-weight: 600;
    }

    p {
      margin: .5rem 0 0 0;
    }
  }

  .skillsGrid{
    flex-direction: column;
    align-content: center;
    margin-top: 0rem;
  }
  .skills {
    margin-top: 1rem;
    p {
      margin-bottom: 1rem;
    }
  }

  .portfolioFrame{
    height: 500px;
  }

  .contactCard{
    padding: 1rem;

    h3 {
      margin: 1.5rem 0 0 0;
      font-size: .75rem;
    }

    h2 {
      margin: 1rem 0 1.5rem 0;
      font-size: 1.25rem;
    }
  }

  .portfolioFrame{
    display: none;
  }

  .portfolioCard {
    padding: 1rem;

    h1 {
      text-align: center;
    }

    a {
      margin-top: 1rem;
    }  
  }
  
  .portfolioGrid{
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
